@import "resources/assets/css/field";
@import "resources/assets/css/tacticlineup";

@media screen and (max-width: 765px) {
	.matchplay_details_desktop {
		display: none;
	}
}

@media screen and (min-width: 765px) and (max-width: 992px) {
	.field {
    width: $field-width;
    flex-basis: calc(100%);
    max-width: calc(100%);
  }
	
	.tplayer {
    width: 10px;
    height: auto;
  }
	
  .player {
    width: 30px;
    height: auto;
  }
	
  .flag {
    width: 20px;
    height: auto;
  }

  .modal-home {
		top: calc(30% - 15%) !important;
    left: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }

	.modal-guest {
		top: calc(30% - 15%) !important;
    right: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }
	
	/* Playerposition -- css/tacticlineup.scss */
  .player1_home {
		position: absolute;
    top: $player1_small_top;
    left: $player1_small_left;
  }
	
	.player2_home {
		position:	absolute;
		top: $player2_small_top;
		left:	$player2_small_left;
	}
	
	.player3_home {
		position: absolute;
		top: $player3_small_top;
		left: $player3_small_left;
	}
	
	.player4_home {
		position: absolute;
		top: $player4_small_top;
		left: $player4_small_left;
	}
	
	.player5_home {
		position: absolute;
		top: $player5_small_top;
		left: $player5_small_left;
	}
	
	.player6_home {
		position: absolute;
		top: $player6_small_top;
		left: $player6_small_left;
	}
	
	.player7_home {
		position: absolute;
		top: $player7_small_top;
		left: $player7_small_left;
	}

	.player8_home {
		position: absolute;
		top: $player8_small_top;
		left: $player8_small_left;
	}
	
	.player9_home {
		position: absolute;
		top: $player9_small_top;
		left: $player9_small_left;
	}
	
	.player10_home {
		position: absolute;
		top: $player10_small_top;
		left: $player10_small_left;
	}
	
	.player11_home {
		position: absolute;
		top: $player11_small_top;
		left: $player11_small_left;
	}
	
	.player12_home {
		position: absolute;
		top: $player12_small_top;
		left: $player12_small_left;
	}
	
	.player13_home {
		position: absolute;
		top: $player13_small_top;
		left: $player13_small_left;
	}
	
	.player14_home {
		position: absolute;
		top: $player14_small_top;
		left: $player14_small_left;
	}

	.player15_home {
		position: absolute;
		top: $player15_small_top;
		left: $player15_small_left;
	}
	
	.player16_home {
		position: absolute;
		top: $player16_small_top;
		left: $player16_small_left;
	}
	
	.player17_home {
		position: absolute;
		top: $player17_small_top;
		left: $player17_small_left;
	}

	.player18_home {
		position: absolute;
		top: $player18_small_top;
		left: $player18_small_left;
	}
	
	.player19_home {
		position: absolute;
		top: $player19_small_top;
		left: $player19_small_left;
	}
	
	.player20_home {
		position: absolute;
		top: $player20_small_top;
		left: $player20_small_left;
	}
	
	.player21_home {
		position: absolute;
		top: $player21_small_top;
		left: $player21_small_left;
	}
	
	.player22_home {
		position: absolute;
		top: $player22_small_top;
		left: $player22_small_left;
	}
	
	.player23_home {
		position: absolute;
		top: $player23_small_top;
		left: $player23_small_left;
	}
	
	.player24_home {
		position: absolute;
		top: $player24_small_top;
		left: $player24_small_left;
	}

	.player25_guest {
		position: absolute;
		top: $player25_small_top;
		right: $player25_small_right;
	}
	
	.player26_guest {
		position: absolute;
		top: $player26_small_top;
		right: $player26_small_right;
	}
	
	.player27_guest {
		position: absolute;
		top: $player27_small_top;
		right: $player27_small_right;
	}
	
	.player28_guest {
		position: absolute;
		top: $player28_small_top;
		right: $player28_small_right;
	}
	
	.player29_guest {
		position: absolute;
		top: $player29_small_top;
		right: $player29_small_right;
	}
	
	.player30_guest {
		position: absolute;
		top: $player30_small_top;
		right: $player30_small_right;
	}
	
	.player31_guest {
		position: absolute;
		top: $player31_small_top;
		right: $player31_small_right;
	}

	.player32_guest {
		position: absolute;
		top: $player32_small_top;
		right: $player32_small_right;
	}
	
	.player33_guest {
		position: absolute;
		top: $player33_small_top;
		right: $player33_small_right;
	}
	
	.player34_guest {
		position: absolute;
		top: $player34_small_top;
		right: $player34_small_right;
	}
	
	.player35_guest {
		position: absolute;
		top: $player35_small_top;
		right: $player35_small_right;
	}
	
	.player36_guest {
		position: absolute;
		top: $player36_small_top;
		right: $player36_small_right;
	}
	
	.player37_guest {
		position: absolute;
		top: $player37_small_top;
		right: $player37_small_right;
	}
	
	.player38_guest {
		position: absolute;
		top: $player38_small_top;
		right: $player38_small_right;
	}
	
	.player39_guest {
		position: absolute;
		top: $player39_small_top;
		right: $player39_small_right;
	}
	
	.player40_guest {
		position: absolute;
		top: $player40_small_top;
		right: $player40_small_right;
	}
	
	.player41_guest {
		position: absolute;
		top: $player41_small_top;
		right: $player41_small_right;
	}

	.player42_guest {
		position: absolute;
		top: $player42_small_top;
		right: $player42_small_right;
	}
	
	.player43_guest {
		position: absolute;
		top: $player43_small_top;
		right: $player43_small_right;
	}
	
	.player44_guest {
		position: absolute;
		top: $player44_small_top;
		right: $player44_small_right;
	}
	
	.player45_guest {
		position: absolute;
		top: $player45_small_top;
		right: $player45_small_right;
	}
	
	.player46_guest {
		position: absolute;
		top: $player46_small_top;
		right: $player46_small_right;
	}
	
	.player47_guest {
		position: absolute;
		top: $player47_small_top;
		right: $player47_small_right;
	}
	
	.player48_guest {
		position: absolute;
		top: $player48_small_top;
		right: $player48_small_right;
	}
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
	.field {
    width: $field-width;
    flex-basis: calc(100%);
    max-width: calc(100%);
  }
	
	.tplayer {
    width: 14px;
    height: auto;
  }
	
  .player {
    width: 39px;
    height: auto;
  }
	
  .flag {
    width: 20px;
    height: auto;
  }

  .modal-home {
		top: calc(30% - 15%) !important;
    left: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }

	.modal-guest {
		top: calc(30% - 15%) !important;
    right: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }
	
	/* Playerposition -- css/tacticlineup.scss */
  .player1_home {
		position: absolute;
    top: $player1_medium_top;
    left: $player1_medium_left;
  }
	
	.player2_home {
		position:	absolute;
		top: $player2_medium_top;
		left:	$player2_medium_left;
	}
	
	.player3_home {
		position: absolute;
		top: $player3_medium_top;
		left: $player3_medium_left;
	}
	
	.player4_home {
		position: absolute;
		top: $player4_medium_top;
		left: $player4_medium_left;
	}
	
	.player5_home {
		position: absolute;
		top: $player5_medium_top;
		left: $player5_medium_left;
	}
	
	.player6_home {
		position: absolute;
		top: $player6_medium_top;
		left: $player6_medium_left;
	}
	
	.player7_home {
		position: absolute;
		top: $player7_medium_top;
		left: $player7_medium_left;
	}

	.player8_home {
		position: absolute;
		top: $player8_medium_top;
		left: $player8_medium_left;
	}
	
	.player9_home {
		position: absolute;
		top: $player9_medium_top;
		left: $player9_medium_left;
	}
	
	.player10_home {
		position: absolute;
		top: $player10_medium_top;
		left: $player10_medium_left;
	}
	
	.player11_home {
		position: absolute;
		top: $player11_medium_top;
		left: $player11_medium_left;
	}
	
	.player12_home {
		position: absolute;
		top: $player12_medium_top;
		left: $player12_medium_left;
	}
	
	.player13_home {
		position: absolute;
		top: $player13_medium_top;
		left: $player13_medium_left;
	}
	
	.player14_home {
		position: absolute;
		top: $player14_medium_top;
		left: $player14_medium_left;
	}

	.player15_home {
		position: absolute;
		top: $player15_medium_top;
		left: $player15_medium_left;
	}
	
	.player16_home {
		position: absolute;
		top: $player16_medium_top;
		left: $player16_medium_left;
	}
	
	.player17_home {
		position: absolute;
		top: $player17_medium_top;
		left: $player17_medium_left;
	}

	.player18_home {
		position: absolute;
		top: $player18_medium_top;
		left: $player18_medium_left;
	}
	
	.player19_home {
		position: absolute;
		top: $player19_medium_top;
		left: $player19_medium_left;
	}
	
	.player20_home {
		position: absolute;
		top: $player20_medium_top;
		left: $player20_medium_left;
	}
	
	.player21_home {
		position: absolute;
		top: $player21_medium_top;
		left: $player21_medium_left;
	}
	
	.player22_home {
		position: absolute;
		top: $player22_medium_top;
		left: $player22_medium_left;
	}
	
	.player23_home {
		position: absolute;
		top: $player23_medium_top;
		left: $player23_medium_left;
	}
	
	.player24_home {
		position: absolute;
		top: $player24_medium_top;
		left: $player24_medium_left;
	}

	.player25_guest {
		position: absolute;
		top: $player25_medium_top;
		right: $player25_medium_right;
	}
	
	.player26_guest {
		position: absolute;
		top: $player26_medium_top;
		right: $player26_medium_right;
	}
	
	.player27_guest {
		position: absolute;
		top: $player27_medium_top;
		right: $player27_medium_right;
	}
	
	.player28_guest {
		position: absolute;
		top: $player28_medium_top;
		right: $player28_medium_right;
	}
	
	.player29_guest {
		position: absolute;
		top: $player29_medium_top;
		right: $player29_medium_right;
	}
	
	.player30_guest {
		position: absolute;
		top: $player30_medium_top;
		right: $player30_medium_right;
	}
	
	.player31_guest {
		position: absolute;
		top: $player31_medium_top;
		right: $player31_medium_right;
	}

	.player32_guest {
		position: absolute;
		top: $player32_medium_top;
		right: $player32_medium_right;
	}
	
	.player33_guest {
		position: absolute;
		top: $player33_medium_top;
		right: $player33_medium_right;
	}
	
	.player34_guest {
		position: absolute;
		top: $player34_medium_top;
		right: $player34_medium_right;
	}
	
	.player35_guest {
		position: absolute;
		top: $player35_medium_top;
		right: $player35_medium_right;
	}
	
	.player36_guest {
		position: absolute;
		top: $player36_medium_top;
		right: $player36_medium_right;
	}
	
	.player37_guest {
		position: absolute;
		top: $player37_medium_top;
		right: $player37_medium_right;
	}
	
	.player38_guest {
		position: absolute;
		top: $player38_medium_top;
		right: $player38_medium_right;
	}
	
	.player39_guest {
		position: absolute;
		top: $player39_medium_top;
		right: $player39_medium_right;
	}
	
	.player40_guest {
		position: absolute;
		top: $player40_medium_top;
		right: $player40_medium_right;
	}
	
	.player41_guest {
		position: absolute;
		top: $player41_medium_top;
		right: $player41_medium_right;
	}

	.player42_guest {
		position: absolute;
		top: $player42_medium_top;
		right: $player42_medium_right;
	}
	
	.player43_guest {
		position: absolute;
		top: $player43_medium_top;
		right: $player43_medium_right;
	}
	
	.player44_guest {
		position: absolute;
		top: $player44_medium_top;
		right: $player44_medium_right;
	}
	
	.player45_guest {
		position: absolute;
		top: $player45_medium_top;
		right: $player45_medium_right;
	}
	
	.player46_guest {
		position: absolute;
		top: $player46_medium_top;
		right: $player46_medium_right;
	}
	
	.player47_guest {
		position: absolute;
		top: $player47_medium_top;
		right: $player47_medium_right;
	}
	
	.player48_guest {
		position: absolute;
		top: $player48_medium_top;
		right: $player48_medium_right;
	}
}

@media screen and (min-width: 1200px) {
  .field {
    width: $field-width;
    flex-basis: calc(100%);
    max-width: calc(100%);
  }

  .tplayer {
    width: 15px;
    height: auto;
  }
	
  .player {
    width: 40px;
    height: auto;
  }
	
  .flag {
    width: 20px;
    height: auto;
  }

  .modal-home {
		top: calc(30% - 15%) !important;
    left: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }

	.modal-guest {
		top: calc(30% - 15%) !important;
    right: calc(85% - 120%) !important;
    margin-top: 0;
    margin-bottom: 0;
  }

	/* Playerposition -- css/tacticlineup.scss */
  .player1_home {
		position: absolute;
    top: $player1_top;
    left: $player1_left;
  }
	
	.player2_home {
		position:	absolute;
		top: $player2_top;
		left:	$player2_left;
	}
	
	.player3_home {
		position: absolute;
		top: $player3_top;
		left: $player3_left;
	}
	
	.player4_home {
		position: absolute;
		top: $player4_top;
		left: $player4_left;
	}
	
	.player5_home {
		position: absolute;
		top: $player5_top;
		left: $player5_left;
	}
	
	.player6_home {
		position: absolute;
		top: $player6_top;
		left: $player6_left;
	}
	
	.player7_home {
		position: absolute;
		top: $player7_top;
		left: $player7_left;
	}

	.player8_home {
		position: absolute;
		top: $player8_top;
		left: $player8_left;
	}
	
	.player9_home {
		position: absolute;
		top: $player9_top;
		left: $player9_left;
	}
	
	.player10_home {
		position: absolute;
		top: $player10_top;
		left: $player10_left;
	}
	
	.player11_home {
		position: absolute;
		top: $player11_top;
		left: $player11_left;
	}
	
	.player12_home {
		position: absolute;
		top: $player12_top;
		left: $player12_left;
	}
	
	.player13_home {
		position: absolute;
		top: $player13_top;
		left: $player13_left;
	}
	
	.player14_home {
		position: absolute;
		top: $player14_top;
		left: $player14_left;
	}

	.player15_home {
		position: absolute;
		top: $player15_top;
		left: $player15_left;
	}
	
	.player16_home {
		position: absolute;
		top: $player16_top;
		left: $player16_left;
	}
	
	.player17_home {
		position: absolute;
		top: $player17_top;
		left: $player17_left;
	}

	.player18_home {
		position: absolute;
		top: $player18_top;
		left: $player18_left;
	}
	
	.player19_home {
		position: absolute;
		top: $player19_top;
		left: $player19_left;
	}
	
	.player20_home {
		position: absolute;
		top: $player20_top;
		left: $player20_left;
	}
	
	.player21_home {
		position: absolute;
		top: $player21_top;
		left: $player21_left;
	}
	
	.player22_home {
		position: absolute;
		top: $player22_top;
		left: $player22_left;
	}
	
	.player23_home {
		position: absolute;
		top: $player23_top;
		left: $player23_left;
	}
	
	.player24_home {
		position: absolute;
		top: $player24_top;
		left: $player24_left;
	}

	.player25_guest {
		position: absolute;
		top: $player25_top;
		right: $player25_right;
	}
	
	.player26_guest {
		position: absolute;
		top: $player26_top;
		right: $player26_right;
	}
	
	.player27_guest {
		position: absolute;
		top: $player27_top;
		right: $player27_right;
	}
	
	.player28_guest {
		position: absolute;
		top: $player28_top;
		right: $player28_right;
	}
	
	.player29_guest {
		position: absolute;
		top: $player29_top;
		right: $player29_right;
	}
	
	.player30_guest {
		position: absolute;
		top: $player30_top;
		right: $player30_right;
	}
	
	.player31_guest {
		position: absolute;
		top: $player31_top;
		right: $player31_right;
	}

	.player32_guest {
		position: absolute;
		top: $player32_top;
		right: $player32_right;
	}
	
	.player33_guest {
		position: absolute;
		top: $player33_top;
		right: $player33_right;
	}
	
	.player34_guest {
		position: absolute;
		top: $player34_top;
		right: $player34_right;
	}
	
	.player35_guest {
		position: absolute;
		top: $player35_top;
		right: $player35_right;
	}
	
	.player36_guest {
		position: absolute;
		top: $player36_top;
		right: $player36_right;
	}
	
	.player37_guest {
		position: absolute;
		top: $player37_top;
		right: $player37_right;
	}
	
	.player38_guest {
		position: absolute;
		top: $player38_top;
		right: $player38_right;
	}
	
	.player39_guest {
		position: absolute;
		top: $player39_top;
		right: $player39_right;
	}
	
	.player40_guest {
		position: absolute;
		top: $player40_top;
		right: $player40_right;
	}
	
	.player41_guest {
		position: absolute;
		top: $player41_top;
		right: $player41_right;
	}

	.player42_guest {
		position: absolute;
		top: $player42_top;
		right: $player42_right;
	}
	
	.player43_guest {
		position: absolute;
		top: $player43_top;
		right: $player43_right;
	}
	
	.player44_guest {
		position: absolute;
		top: $player44_top;
		right: $player44_right;
	}
	
	.player45_guest {
		position: absolute;
		top: $player45_top;
		right: $player45_right;
	}
	
	.player46_guest {
		position: absolute;
		top: $player46_top;
		right: $player46_right;
	}
	
	.player47_guest {
		position: absolute;
		top: $player47_top;
		right: $player47_right;
	}
	
	.player48_guest {
		position: absolute;
		top: $player48_top;
		right: $player48_right;
	}
}