@import "resources/assets/css/header";


/* ->307 ---------------------------------------------------- css/header.scss*/
@media screen and (min-width: 320px) and (min-height: 150px) {
  /* show more pictures */

  .header {
    background-color: $header-bgcolor;
  }
  .headerImage {
    background: $header-bgcolor url('/images/header/logo.png') no-repeat $headerimage-mobile-position ;
    background-size: $headerimage-mobile-width $headerimage-mobile-height;
    width: $headerimage-width;
    height: $headerimage-height;
  }

  .headerTitle {
    position: $headertitle-mobile-position;
    left: $headertitle-mobile-left;
    top: $headertitle-mobile-top;
    font-size: $headertitle-mobile-font-size;
    color: $headertitle-mobile-color;
  }
  .headerSubTitle {
    position: $headersubtitle-mobile-position;
    left: $headersubtitle-mobile-left;
    top: $headersubtitle-mobile-top;
    font-size: $headersubtitle-mobile-font-size;
    color: $headersubtitle-mobile-color;
  }


  @media screen and (min-width: 1080px) and (min-height: 250px) {
    .header {
      position:relative;
      margin:auto;

      width:$header-width;
      height: $header-height;
    }

    .headerImage {
      position: absolute;
      bottom: 0px;
      background: $header-bgcolor url('/images/header/header.png') no-repeat;
      background-size: $headerimage-width $headerimage-height;
      width: $headerimage-width;
      height: $headerimage-height;
    }
    .headerImagebuli {
      position: absolute;
      bottom: 0px;
      background: $header-bgcolor url('/images/header/header_buli_1.png') no-repeat;
      background-size: $headerimage-width $headerimage-height;
      width: $headerimage-width;
      height: $headerimage-height;
    }
    .headerImagebuli2 {
      position: absolute;
      bottom: 0px;
      background: $header-bgcolor url('/images/header/header_buli_2.png') no-repeat;
      background-size: $headerimage-width $headerimage-height;
      width: $headerimage-width;
      height: $headerimage-height;
    }
    .headerImagebuli3 {
      position: absolute;
      bottom: 0px;
      background: $header-bgcolor url('/images/header/header_buli_3.png') no-repeat;
      background-size: $headerimage-width $headerimage-height;
      width: $headerimage-width;
      height: $headerimage-height;
    }
    .headerImagedfbpokal {
      position: absolute;
      bottom: 0px;
      background: $header-bgcolor url('/images/header/header_dfbpokal.png') no-repeat;
      background-size: $headerimage-width $headerimage-height;
      width: $headerimage-width;
      height: $headerimage-height;
    }


    .headerTitle {
      position: $headertitle-position;
      left: $headertitle-left;
      top: $headertitle-top;
      font-size: $headertitle-font-size;
      color: $headertitle-color;
    }
    .headerSubTitle {
      position: $headersubtitle-position;
      left: $headersubtitle-left;
      top: $headersubtitle-top;
      font-size: $headersubtitle-font-size;
      color: $headersubtitle-color;
    }
  }
}