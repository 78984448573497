@import "resources/assets/css/teaminfo";

.teampictab_th{
  width:$widthteampictab_th;

}

.teampictab_td{
  width:$widthteampic;
}
.teaminfotab_th{
  width:$widthteaminfotab_th;

}
.teaminfotab_td{
  width:$widthteaminfotab_td;
}
.teampic{
  width:$widthteampic;
  height:$heightteampic;
}
