.rankingHeader {font-weight: bold;}

.rankingList .row {
    border-top: 1px solid #dddddd;
}

.rankingPlaceFirst {background-color: #ffeb80;}
.rankingPlaceSecond {background-color: #e6e6e6;}
.rankingPlaceThird {background-color: #cdb17b;}

.rankingSelectSeason {
    margin: 0 auto;
    width: 60%;
}

.rankingSelectFromMatchday {
    width: 80%;
}

.rankingSelectFromRound {
    float: right;
    width: 80%;
}

.rankingSelectHeadline {
    font-weight: bold;
}

.rankingSelectToMatchday {
    width: 80%;
}

.rankingSelectToRound {
    float: right;
    width: 80%;
}