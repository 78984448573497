

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;


/* ---------------------------------------------------------------------------------- Menuheader*/
$dropdown-header-color: #1e344d;
$navbar-default-bg: $dropdown-header-color;
$navbar-default-brand-hover-bg:            transparent !default;
$navbar-default-link-hover-bg:             lighten($dropdown-header-color,10%) !default;


//** ------------------------------------Menu text color.
$navbar-default-link-color: #eeeeee !default;
$navbar-default-link-active-color: darken($navbar-default-link-color,13%) !default;

// Hover text color
$navbar-default-link-hover-color:  darken($navbar-default-link-color, 5%) !default;
$navbar-default-brand-hover-color: $navbar-default-link-hover-color;


//** ------------------------------------dropdown color.
$dropdown-bg:  lighten($dropdown-header-color,70%) !default;

// Active dropdown menu item text color.
$dropdown-link-active-color: darken($text-color, 70%) !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: darken($dropdown-bg, 7%) !default;



// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

