
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

@font-face {
    font-family: dsDigital;
    src: url(../../fonts/digib.ttf);
}

// Variables
@import "variables";
@import "tactic_lineup_variables";
@import "header_variables";
@import "teaminfo_variables";
@import "_table_definitions";


// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-tagsinput/dist/bootstrap-tagsinput";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// select2
@import "node_modules/select2/dist/css/select2";

// datatables
@import "node_modules/datatables/media/css/jquery.dataTables";

// datetimepicker
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";

// Own stylesheets
@import "resources/assets/css/matchcenter";
@import "resources/assets/css/ranking";
@import "resources/assets/css/profile";
@import "resources/assets/css/avatars";


html, body {
  height: 100%;
}



.bootstrap-tagsinput {
  width: 100% !important;
}

.bootstrap-tagsinput input {
  width: 100% !important;
}

.no-margin, .no-margin-top {
  margin-top: 0;
}

.no-margin, .no-margin-left {
  margin-left: 0;
}

.no-margin, .no-margin-right {
  margin-right: 0;
}

.no-margin, .no-margin-bottom {
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.me {
  background-color: #dddddd;
}

.message-members {
  margin-bottom: 10px;
}

.nav li a.league-chooser {
  background-color: lighten($dropdown-header-color,10%);
}

tr.game td {
    padding-top: 4px;
    padding-bottom: 4px;
}

table.leaguetable tr td.number {
  font-size: 18px;
  text-align: center;
}

table.leaguetable tr td.name {
  font-size: 16px;
}

table.leaguetable tr th.number {
  text-align: center;
}

table.leaguetable tr td {
  line-height: 32px;
}

table.leaguetable tr td img.team-logo {
  max-height: 32px;
}

table.leaguetable.compact tr td.number {
  font-size: 16px;
  text-align: center;
}

table.leaguetable.compact tr td.name {
  font-size: 14px;
}

table.leaguetable.compact tr td {
  line-height: 24px;
}

table.leaguetable.compact tr td img.team-logo {
  max-height: 24px;
}

table.leaguetable tr td img.team-logo {
  max-height: 32px;
}

div.legend {
  float:left;
  width: 20px;
  height: 20px;
}

div.legend-text {
  padding-left: 25px;
}

table.leaguetable .trend-up {
  color: green;
}

table.leaguetable .trend-down {
  color: red;
}

table.leaguetable .live {
  color: red;
  animation: blink 1s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.0;
  }
}

.pointsResult {
  color: #77ee77;
}

.pointsDifference {
  color: #ff2222;
}

.pointsTendency {
  color: #FACC2E;
}

.live {
  color: red;
  font-weight: bold;
}

/* Style for bonus tips */
.bonusQuestionStandard {
  display: none;
}

.hiddenBonusPoints {
  display: none;
}

#bonusQuestionOverviewTable {
  width: 100%;
}

#bonusQuestionOverviewTable tr td {
  padding-bottom: 4px;
  padding-top: 4px;
}

.profile-link {
  color: #636b6f;
}

.profile-link:hover {
  color: #000000;
  text-decoration: none;
}

.navbar.navbar-main {
  border: 0!important;
}

.contents .panel:first-of-type {
  border-radius: 0px!important;
  border: 0!important;
}

@media (min-width: $screen-md-max) {
  .navbar.navbar-main {
    border-radius: 0px!important;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .table-responsive {
    display: block;
  }
}

/* FF FIX */
@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}
@media screen and (min-width: 1080px) and (min-height: 250px) {


  .navbar.navbar-default{
    width:1140px !important;

    margin:auto;

  }
  .navbar-nav > li > a, .navbar-brand {
    padding-top:4px !important;
    padding-bottom:0 !important;
    height: 28px;
  }
  .navbar {min-height:28px !important;}

}