/* ---------------------------------------------------- Header sass/app.scss Line 238-245*/
/* header*/
$header-width: 1140px;
$header-height: 200px;
$header-bgcolor: #000000;

/* headerimage*/
$headerimage-width: 100%;
$headerimage-height: 200px;

/* headertitle*/
$headertitle-position: absolute;
$headertitle-left:40px;
$headertitle-top: 80px;
$headertitle-font-size: 34px;
$headertitle-color: #f4f4f4;

/* headersubtitle*/
$headersubtitle-position: absolute;
$headersubtitle-left:40px;
$headersubtitle-top: 120px;
$headersubtitle-font-size: 20px;
$headersubtitle-color: #f4f4f4;

/* headerimage-mobile*/
$headerimage-mobile-width: 100px;
$headerimage-mobile-height: 100px;
$headerimage-mobile-position: center;

/* headertitle-mobile*/
$headertitle-mobile-position: absolute;
$headertitle-mobile-left:40px;
$headertitle-mobile-top: 20px;
$headertitle-mobile-font-size: 20px;
$headertitle-mobile-color: #f4f4f4;

/* headersubtitle-mobile*/
$headersubtitle-mobile-position: absolute;
$headersubtitle-mobile-left:40px;
$headersubtitle-mobile-top: 40px;
$headersubtitle-mobile-font-size: 14px;
$headersubtitle-mobile-color: #f4f4f4;

