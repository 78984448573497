$widthteampic:35%;
$heightteampic:auto;


$widthteampictab_th:5%;


$widthteampictab_td:5%;


$widthteaminfotab_th:90%;


$widthteaminfotab_td:90%;
