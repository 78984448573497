#games .row:hover {
    background-color: #eeeeee;
}

#liveticker {
    padding-top: 10px;
}

.liveticker {
    background: url('../../images/tafel.png');
    background-repeat: repeat;
    background-color: #555;
    color: white;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.liveticker-logo {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    opacity: 0.9;
}

.liveticker-result-half {
  line-height: 1.0;
}

.liveticker-result {
  line-height: 65px;
  height: 65px;
}

.liveticker img {
    height: 65px;
    width: auto;
}

.livetickerHalfResult {
    font-size: 24px;
    font-family: 'dsDigital';
}

.livetickerHeader {
    font-size: 15px;
    font-weight: bold;
}

.livetickerResult {
    font-size: 50px;
    font-family: 'dsDigital';
}

.selectMatchdayLi {
    padding-top: 8px;
}

.selectSeason {
    width: 120px;
}

.selectSeasonLi {
    margin-right: 10px;
    padding-left: 10px;
    padding-top: 4px;
}

.live {
    color: red;
    font-weight: bold;
}

.tl {
  width: 100%;
  height: 80px;
  background-color: lightgray;
}

.tl > .team {
  height: 30px;
  width: 100%;
  line-height: 30px;
  padding: 0px 2px;
}

.tl > .team > small {
  color: gray;
}

.tl > .time {
  height: 20px;
  width: 100%;
  background-color: gray;
  color: white;
  padding: 0px 2px;
  font-size: 13px;
}

.tl > .time > div {
  margin:0;
  padding:0;
  z-index: 0;
}

.tl > .team > .events {
    position: relative;
}

.tl > .team > .events > .event {
    position: absolute;
}

.tl > .team > .events > .event.home {
    padding-bottom: 5px;
    margin-left: -10px;
}

.tl > .team > .events > .event.guest {
    padding-bottom: 5px;
    margin-left: -10px;
}

.tl > .team > .events > .event > .icon {
    display: block;
    text-align: center;
    z-index: 1;
    color: lightgrey;
}

.tl > .team > .events > .event.home > .icon {
    margin-top: -6px;
}

.tl > .team > .events > .event.guest > .icon {
    margin-top: -9px;
}

.tl > .team > .events > .event.guest > img {
    margin-top: -15px;
    margin-left: 1px;
}

.sub-out {
    color: red;
}

.sub-in {
    color: green;
}

.scheduleTeamDiv {
    margin-bottom: 20px;
}

.scheduleTeamList {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0px;
}

.scheduleTeamList img {
    height: 40px;
    width: auto;
}

.scheduleMatchdayDiv {
    margin-bottom: 20px;
}

.scheduleMatchdayDiv button {
    margin-bottom: 5px;
}

#scheduleOverviewTable {width: 100% !important;}
#scheduleOverviewTable_wrapper img {height: 25px; width: auto;}
#scheduleOverviewTable_wrapper th:nth-child(1) {text-align: center; width: 20% !important;}
#scheduleOverviewTable_wrapper th:nth-child(2) {text-align: right; width: 35% !important;}
#scheduleOverviewTable_wrapper th:nth-child(3) {text-align: center; width: 5% !important;}
#scheduleOverviewTable_wrapper th:nth-child(4) {text-align: left; width: 35% !important;}
#scheduleOverviewTable_wrapper td:nth-child(1) {text-align: center;}
#scheduleOverviewTable_wrapper td:nth-child(2) {text-align: right;}
#scheduleOverviewTable_wrapper td:nth-child(3) {text-align: center;}
#scheduleOverviewTable_wrapper td:nth-child(4) {text-align: left;}
#scheduleOverviewTable_wrapper td:nth-child(5) {text-align: center;}

.activeTeam {box-shadow: 0px 2px #ff0000;}

@media screen and (max-width: 765px) {
	.matchplay_details_mobil {display: block;}
}

@media screen and (min-width: 765px) and (max-width: 992px) {
	.matchplay_details_mobil {display: none;}
	
	.gameTacticTableGuestHead {position: absolute; right: 55px; top: 415px;}
	.gameTacticTableGuestHead img {height: 15px; width: 15px;}
	.gameTacticTableHomeHead {left: 55px; position: absolute; top: 415px;}
	.gameTacticTableHomeHead img {height: 15px; width: 15px;}
	.gameTacticTableHeadLogo {float: left; margin-left: 10px; margin-right: 10px;}
	.gameTacticTableHeadName {float: left; font-size: 12px; margin-top: 3px;}

	.gameTacticTableHead {color: #ffffff; left: 279px; position: absolute; top: 416px;}
	.gameTacticTableHeadGuestTeam {float: left; font-size: 5px; text-align: center; width: 59px;}
	.gameTacticTableHeadGuestTeam img {height: 15px; width: 15px;}
	.gameTacticTableHeadHomeTeam {float: left; font-size: 5px; margin-right: 4px; text-align: center; width: 62px;}
	.gameTacticTableHeadHomeTeam img {height: 15px; width: 15px;}
	.gameTacticTableHeadResults {float: left; font-size: 15px; font-weight: bold; width: 34px;}

	.gameTacticTablePlayer {font-size: 8px; width: 90px;}

	.gameTacticTableHomePlayer1 {left: 55px; position: absolute; top: 440px;}
	.gameTacticTableHomePlayer2 {left: 55px; position: absolute; top: 454px;}
	.gameTacticTableHomePlayer3 {left: 55px; position: absolute; top: 470px;}
	.gameTacticTableHomePlayer4 {left: 55px; position: absolute; top: 484px;}
	.gameTacticTableHomePlayer5 {left: 156px; position: absolute; top: 440px;}
	.gameTacticTableHomePlayer6 {left: 156px; position: absolute; top: 454px;}
	.gameTacticTableHomePlayer7 {left: 156px; position: absolute; top: 470px;}
	.gameTacticTableHomePlayer8 {left: 156px; position: absolute; top: 484px;}
	.gameTacticTableHomePlayer9 {left: 262px; position: absolute; top: 454px;}
	.gameTacticTableHomePlayer10 {left: 262px; position: absolute; top: 470px;}
	.gameTacticTableHomePlayer11 {left: 262px; position: absolute; top: 484px;}

	.gameTacticTableGuestPlayer1 {left: 576px; position: absolute; top: 440px;}
	.gameTacticTableGuestPlayer2 {left: 576px; position: absolute; top: 454px;}
	.gameTacticTableGuestPlayer3 {left: 576px; position: absolute; top: 470px;}
	.gameTacticTableGuestPlayer4 {left: 576px; position: absolute; top: 484px;}
	.gameTacticTableGuestPlayer5 {left: 472px; position: absolute; top: 440px;}
	.gameTacticTableGuestPlayer6 {left: 472px; position: absolute; top: 454px;}
	.gameTacticTableGuestPlayer7 {left: 472px; position: absolute; top: 470px;}
	.gameTacticTableGuestPlayer8 {left: 472px; position: absolute; top: 484px;}
	.gameTacticTableGuestPlayer9 {left: 372px; position: absolute; top: 454px;}
	.gameTacticTableGuestPlayer10 {left: 372px; position: absolute; top: 470px;}
	.gameTacticTableGuestPlayer11 {left: 372px; position: absolute; top: 484px;}
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
	.matchplay_details_mobil {display: none;}
	
	.gameTacticTableGuestHead {position: absolute; right: 75px; top: 526px;}
	.gameTacticTableGuestHead img {height: 20px; width: 20px;}
	.gameTacticTableHomeHead {left: 75px; position: absolute; top: 526px;}
	.gameTacticTableHomeHead img {height: 20px; width: 20px;}
	.gameTacticTableHeadLogo {float: left; margin-left: 10px; margin-right: 10px;}
	.gameTacticTableHeadName {float: left; margin-top: 3px;}

	.gameTacticTableHead {color: #ffffff; left: 363px; position: absolute; top: 523px;}
	.gameTacticTableHeadGuestTeam {float: left; font-size: 7px; text-align: center; width: 84px;}
	.gameTacticTableHeadGuestTeam img {height: 18px; width: 18px;}
	.gameTacticTableHeadHomeTeam {float: left; font-size: 7px; margin-right: 8px; text-align: center; width: 78px;}
	.gameTacticTableHeadHomeTeam img {height: 18px; width: 18px;}
	.gameTacticTableHeadResults {float: left; font-size: 22px; font-weight: bold; width: 43px;}

	.gameTacticTablePlayer {font-size: 10px; width: 115px;}

	.gameTacticTableHomePlayer1 {left: 75px; position: absolute; top: 554px;}
	.gameTacticTableHomePlayer2 {left: 75px; position: absolute; top: 575px;}
	.gameTacticTableHomePlayer3 {left: 75px; position: absolute; top: 594px;}
	.gameTacticTableHomePlayer4 {left: 75px; position: absolute; top: 613px;}
	.gameTacticTableHomePlayer5 {left: 208px; position: absolute; top: 554px;}
	.gameTacticTableHomePlayer6 {left: 208px; position: absolute; top: 575px;}
	.gameTacticTableHomePlayer7 {left: 208px; position: absolute; top: 594px;}
	.gameTacticTableHomePlayer8 {left: 208px; position: absolute; top: 613px;}
	.gameTacticTableHomePlayer9 {left: 344px; position: absolute; top: 575px;}
	.gameTacticTableHomePlayer10 {left: 344px; position: absolute; top: 594px;}
	.gameTacticTableHomePlayer11 {left: 344px; position: absolute; top: 613px;}

	.gameTacticTableGuestPlayer1 {left: 760px; position: absolute; top: 554px;}
	.gameTacticTableGuestPlayer2 {left: 760px; position: absolute; top: 574px;}
	.gameTacticTableGuestPlayer3 {left: 760px; position: absolute; top: 594px;}
	.gameTacticTableGuestPlayer4 {left: 760px; position: absolute; top: 613px;}
	.gameTacticTableGuestPlayer5 {left: 622px; position: absolute; top: 554px;}
	.gameTacticTableGuestPlayer6 {left: 622px; position: absolute; top: 574px;}
	.gameTacticTableGuestPlayer7 {left: 622px; position: absolute; top: 594px;}
	.gameTacticTableGuestPlayer8 {left: 622px; position: absolute; top: 613px;}
	.gameTacticTableGuestPlayer9 {left: 490px; position: absolute; top: 574px;}
	.gameTacticTableGuestPlayer10 {left: 490px; position: absolute; top: 594px;}
	.gameTacticTableGuestPlayer11 {left: 490px; position: absolute; top: 613px;}
}

@media screen and (min-width: 1200px) {
	.matchplay_details_mobil {display: none;}
	
	.gameTacticTableGuestHead {position: absolute; right: 75px; top: 627px;}
	.gameTacticTableGuestHead img {height: 25px; width: 25px;}
	.gameTacticTableHomeHead {left: 75px; position: absolute; top: 627px;}
	.gameTacticTableHomeHead img {height: 25px; width: 25px;}
	.gameTacticTableHeadLogo {float: left; margin-left: 10px; margin-right: 10px;}
	.gameTacticTableHeadName {float: left; margin-top: 3px;}

	.gameTacticTableHead {color: #ffffff; left: 442px; position: absolute; top: 621px;}
	.gameTacticTableHeadGuestTeam {float: left; font-size: 8px; text-align: center; width: 96px;}
	.gameTacticTableHeadGuestTeam img {height: 20px; width: 20px;}
	.gameTacticTableHeadHomeTeam {float: left; font-size: 8px; margin-right: 8px; text-align: center; width: 96px;}
	.gameTacticTableHeadHomeTeam img {height: 20px; width: 20px;}
	.gameTacticTableHeadResults {float: left; font-size: 24px; font-weight: bold; width: 55px;}

	.gameTacticTablePlayer {font-size: 12px; width: 150px;}

	.gameTacticTableHomePlayer1 {left: 75px; position: absolute; top: 659px;}
	.gameTacticTableHomePlayer2 {left: 75px; position: absolute; top: 684px;}
	.gameTacticTableHomePlayer3 {left: 75px; position: absolute; top: 707px;}
	.gameTacticTableHomePlayer4 {left: 75px; position: absolute; top: 730px;}
	.gameTacticTableHomePlayer5 {left: 240px; position: absolute; top: 659px;}
	.gameTacticTableHomePlayer6 {left: 240px; position: absolute; top: 684px;}
	.gameTacticTableHomePlayer7 {left: 240px; position: absolute; top: 707px;}
	.gameTacticTableHomePlayer8 {left: 240px; position: absolute; top: 730px;}
	.gameTacticTableHomePlayer9 {left: 406px; position: absolute; top: 684px;}
	.gameTacticTableHomePlayer10 {left: 406px; position: absolute; top: 707px;}
	.gameTacticTableHomePlayer11 {left: 406px; position: absolute; top: 730px;}

	.gameTacticTableGuestPlayer1 {left: 915px; position: absolute; top: 659px;}
	.gameTacticTableGuestPlayer2 {left: 915px; position: absolute; top: 684px;}
	.gameTacticTableGuestPlayer3 {left: 915px; position: absolute; top: 707px;}
	.gameTacticTableGuestPlayer4 {left: 915px; position: absolute; top: 730px;}
	.gameTacticTableGuestPlayer5 {left: 750px; position: absolute; top: 659px;}
	.gameTacticTableGuestPlayer6 {left: 750px; position: absolute; top: 684px;}
	.gameTacticTableGuestPlayer7 {left: 750px; position: absolute; top: 707px;}
	.gameTacticTableGuestPlayer8 {left: 750px; position: absolute; top: 730px;}
	.gameTacticTableGuestPlayer9 {left: 585px; position: absolute; top: 684px;}
	.gameTacticTableGuestPlayer10 {left: 585px; position: absolute; top: 707px;}
	.gameTacticTableGuestPlayer11 {left: 585px; position: absolute; top: 730px;}
}

.gameTacticClearBoth {clear: both;}
#matchcenterPlayerDetailModal .modal-header .container-fluid {text-align: center;}
#matchcenterPlayerDetailModal hr {margin-bottom: 10px; margin-top: 10px;}