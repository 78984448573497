/* Playerposition defense home -- css/tacticlineup.scss */
$player1_top: 325px;
$player1_left: 220px;

$player2_top: 230px;
$player2_left: 315px;

$player3_top: 265px;
$player3_left: 305px;

$player4_top: 283px;
$player4_left: 302px;

$player5_top: 320px;
$player5_left: 290px;

$player6_top: 345px;
$player6_left: 284px;

$player7_top: 385px;
$player7_left: 271px;

$player8_top: 415px;
$player8_left: 263px;

$player9_top: 445px;
$player9_left: 255px;

$player10_top: 485px;
$player10_left: 245px;

/* Playerposition midfield home -- css/tacticlineup.scss */
$player11_top: 235px;
$player11_left: 400px;

$player12_top: 262px;
$player12_left: 396px;

$player13_top: 283px;
$player13_left: 390px;

$player14_top: 320px;
$player14_left: 385px;

$player15_top: 345px;
$player15_left: 378px;

$player16_top: 386px;
$player16_left: 373px;

$player17_top: 415px;
$player17_left: 366px;

$player18_top: 447px;
$player18_left: 362px;

$player19_top: 480px;
$player19_left: 355px;

/* Playerposition forward home -- css/tacticlineup.scss */
$player20_top: 283px;
$player20_left: 480px;

$player21_top: 310px;
$player21_left: 485px;

$player22_top: 345px;
$player22_left: 472px;

$player23_top: 380px;
$player23_left: 479px;

$player24_top: 415px;
$player24_left: 465px;

/* Playerposition defense guest -- css/tacticlineup.scss */
$player25_top: 345px;
$player25_right: 214px;

$player26_top: 230px;
$player26_right: 310px;

$player27_top: 262px;
$player27_right: 302px;

$player28_top: 283px;
$player28_right: 296px;

$player29_top: 320px;
$player29_right: 285px;

$player30_top: 345px;
$player30_right: 278px;

$player31_top: 386px;
$player31_right: 267px;

$player32_top: 415px;
$player32_right: 258px;

$player33_top: 447px;
$player33_right: 249px;

$player34_top: 485px;
$player34_right: 235px;

/* Playerposition midfield guest -- css/tacticlineup.scss */
$player35_top: 235px;
$player35_right: 395px;

$player36_top: 262px;
$player36_right: 390px;

$player37_top: 283px;
$player37_right: 385px;

$player38_top: 320px;
$player38_right: 378px;

$player39_top: 345px;
$player39_right: 375px;

$player40_top: 386px;
$player40_right: 367px;

$player41_top: 415px;
$player41_right: 360px;

$player42_top: 447px;
$player42_right: 358px;

$player43_top: 480px;
$player43_right: 350px;

/* Playerposition forward guest -- css/tacticlineup.scss */
$player44_top: 283px;
$player44_right: 467px;

$player45_top: 310px;
$player45_right: 480px;

$player46_top: 345px;
$player46_right: 466px;

$player47_top: 380px;
$player47_right: 478px;

$player48_top: 415px;
$player48_right: 464px;

/* Playerposition defense home - medium -- css/tacticlineup.scss */
$player1_medium_top: 272px;
$player1_medium_left: 180px;

$player2_medium_top: 204px;
$player2_medium_left: 256px;

$player3_medium_top: 225px;
$player3_medium_left: 250px;

$player4_medium_top: 244px;
$player4_medium_left: 244px;

$player5_medium_top: 268px;
$player5_medium_left: 238px;

$player6_medium_top: 292px;
$player6_medium_left: 230px;

$player7_medium_top: 325px;
$player7_medium_left: 220px;

$player8_medium_top: 348px;
$player8_medium_left: 214px;

$player9_medium_top: 370px;
$player9_medium_left: 208px;

$player10_medium_top: 400px;
$player10_medium_left: 200px;

/* Playerposition midfield home - medium -- css/tacticlineup.scss */
$player11_medium_top: 206px;
$player11_medium_left: 328px;

$player12_medium_top: 225px;
$player12_medium_left: 326px;

$player13_medium_top: 244px;
$player13_medium_left: 320px;

$player14_medium_top: 268px;
$player14_medium_left: 318px;

$player15_medium_top: 292px;
$player15_medium_left: 312px;

$player16_medium_top: 325px;
$player16_medium_left: 306px;

$player17_medium_top: 348px;
$player17_medium_left: 300px;

$player18_medium_top: 370px;
$player18_medium_left: 298px;

$player19_medium_top: 400px;
$player19_medium_left: 290px;

/* Playerposition forward home - medium -- css/tacticlineup.scss */
$player20_medium_top: 244px;
$player20_medium_left: 394px;

$player21_medium_top: 266px;
$player21_medium_left: 392px;

$player22_medium_top: 292px;
$player22_medium_left: 388px;

$player23_medium_top: 322px;
$player23_medium_left: 386px;

$player24_medium_top: 348px;
$player24_medium_left: 382px;

/* Playerposition defense guest - medium -- css/tacticlineup.scss */

$player25_medium_top: 290px;
$player25_medium_right: 178px;

$player26_medium_top: 204px;
$player26_medium_right: 248px;

$player27_medium_top: 225px;
$player27_medium_right: 242px;

$player28_medium_top: 244px;
$player28_medium_right: 238px;

$player29_medium_top: 268px;
$player29_medium_right: 230px;

$player30_medium_top: 292px;
$player30_medium_right: 224px;

$player31_medium_top: 325px;
$player31_medium_right: 214px;

$player32_medium_top: 348px;
$player32_medium_right: 208px;

$player33_medium_top: 370px;
$player33_medium_right: 200px;

$player34_medium_top: 400px;
$player34_medium_right: 194px;

/* Playerposition midfield guest - medium -- css/tacticlineup.scss */
$player35_medium_top: 206px;
$player35_medium_right: 318px;

$player36_medium_top: 225px;
$player36_medium_right: 314px;

$player37_medium_top: 244px;
$player37_medium_right: 312px;

$player38_medium_top: 268px;
$player38_medium_right: 306px;

$player39_medium_top: 292px;
$player39_medium_right: 304px;

$player40_medium_top: 325px;
$player40_medium_right: 298px;

$player41_medium_top: 348px;
$player41_medium_right: 294px;

$player42_medium_top: 370px;
$player42_medium_right: 290px;

$player43_medium_top: 400px;
$player43_medium_right: 284px;

/* Playerposition forward guest - medium -- css/tacticlineup.scss */
$player44_medium_top: 244px;
$player44_medium_right: 380px;

$player45_medium_top: 266px;
$player45_medium_right: 390px;

$player46_medium_top: 292px;
$player46_medium_right: 378px;

$player47_medium_top: 322px;
$player47_medium_right: 384px;

$player48_medium_top: 348px;
$player48_medium_right: 372px;

/* Playerposition defense home - small -- css/tacticlineup.scss */
$player1_small_top: 230px;
$player1_small_left: 140px;

$player2_small_top: 174px;
$player2_small_left: 196px;

$player3_small_top: 188px;
$player3_small_left: 190px;

$player4_small_top: 206px;
$player4_small_left: 188px;

$player5_small_top: 224px;
$player5_small_left: 180px;

$player6_small_top: 240px;
$player6_small_left: 178px;

$player7_small_top: 266px;
$player7_small_left: 168px;

$player8_small_top: 280px;
$player8_small_left: 166px;

$player9_small_top: 298px;
$player9_small_left: 160px;

$player10_small_top: 324px;
$player10_small_left: 154px;

/* Playerposition midfield home - small -- css/tacticlineup.scss */
$player11_small_top: 178px;
$player11_small_left: 250px;

$player12_small_top: 188px;
$player12_small_left: 250px;

$player13_small_top: 206px;
$player13_small_left: 246px;

$player14_small_top: 224px;
$player14_small_left: 242px;

$player15_small_top: 240px;
$player15_small_left: 240px;

$player16_small_top: 266px;
$player16_small_left: 234px;

$player17_small_top: 280px;
$player17_small_left: 232px;

$player18_small_top: 298px;
$player18_small_left: 228px;

$player19_small_top: 320px;
$player19_small_left: 224px;

/* Playerposition forward home - small -- css/tacticlineup.scss */
$player20_small_top: 206px;
$player20_small_left: 300px;

$player21_small_top: 218px;
$player21_small_left: 300px;

$player22_small_top: 240px;
$player22_small_left: 296px;

$player23_small_top: 264px;
$player23_small_left: 294px;

$player24_small_top: 280px;
$player24_small_left: 292px;

/* Playerposition defense guest - small -- css/tacticlineup.scss */

$player25_small_top: 240px;
$player25_small_right: 138px;

$player26_small_top: 174px;
$player26_small_right: 191px;

$player27_small_top: 188px;
$player27_small_right: 188px;

$player28_small_top: 206px;
$player28_small_right: 182px;

$player29_small_top: 224px;
$player29_small_right: 176px;

$player30_small_top: 240px;
$player30_small_right: 172px;

$player31_small_top: 266px;
$player31_small_right: 164px;

$player32_small_top: 280px;
$player32_small_right: 162px;

$player33_small_top: 298px;
$player33_small_right: 156px;

$player34_small_top: 324px;
$player34_small_right: 150px;

/* Playerposition midfield guest - small -- css/tacticlineup.scss */
$player35_small_top: 178px;
$player35_small_right: 246px;

$player36_small_top: 188px;
$player36_small_right: 244px;

$player37_small_top: 206px;
$player37_small_right: 242px;

$player38_small_top: 224px;
$player38_small_right: 238px;

$player39_small_top: 240px;
$player39_small_right: 236px;

$player40_small_top: 266px;
$player40_small_right: 228px;

$player41_small_top: 280px;
$player41_small_right: 228px;

$player42_small_top: 298px;
$player42_small_right: 222px;

$player43_small_top: 320px;
$player43_small_right: 220px;

/* Playerposition forward guest - small -- css/tacticlineup.scss */
$player44_small_top: 206px;
$player44_small_right: 292px;

$player45_small_top: 218px;
$player45_small_right: 296px;

$player46_small_top: 240px;
$player46_small_right: 290px;

$player47_small_top: 264px;
$player47_small_right: 294px;

$player48_small_top: 280px;
$player48_small_right: 286px;