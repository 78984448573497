table.leaguetable tr.definition.cl td, div.legend.cl {
  background-color: #88e688;
}

table.leaguetable tr.definition.ascent td, div.legend.ascent {
  background-color: #88e688;
}

table.leaguetable tr.definition.clquali td, div.legend.clquali {
  background-color: #bfe192;
}

table.leaguetable tr.definition.relegationgameup td, div.legend.relegationgameup {
  background-color: #bfe192;
}

table.leaguetable tr.definition.el td, div.legend.el {
  background-color: #99cdeb;
}

table.leaguetable tr.definition.elquali td, div.legend.elquali {
  background-color: #bde8f6;
}

table.leaguetable tr.definition.relegationgame td, div.legend.relegationgame {
  background-color: rgba(255, 160, 122, 0.51);
}

table.leaguetable tr.definition.relegation td, div.legend.relegation {
  background-color: rgba(255, 92, 92, 0.45);
}